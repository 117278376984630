import { ApiEndpoints } from '@/domains/endpoints';
import { TConvertToPDFParams, TConvertToPDFResponse } from '@/domains/modules';
import { Env } from '@/configs/env';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

const option: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
    Apikey: Env.convertDocxBase64PdfKey
  },
  responseType: 'arraybuffer'
};

export const ConvertServices = {
  convertToPDF: ({
    inputFile,
    type
  }: TConvertToPDFParams): Promise<AxiosResponse<TConvertToPDFResponse>> => {
    const formData = new FormData();
    formData.append('inputFile', inputFile);
    return axios.post(
      ApiEndpoints.CONVERT_TO_PDF_URL.replace(':type', type),
      formData,
      option
    );
  }
};
