import type { RouteObject } from 'react-router-dom';

import { LazyLoad } from '@/components';
import { Endpoints } from '@/configs/common/endpoints';
import AuthGuards from './guard.config';

export const Routes = (): RouteObject[] => [
  {
    path: Endpoints.HOME,
    element: LazyLoad(() => import('@/pages/home'))
  },
  {
    path: Endpoints.ABOUT,
    element: LazyLoad(() => import('@/pages/about'))
  },
  {
    path: Endpoints.FAQ,
    element: LazyLoad(() => import('@/pages/faq'))
  },
  {
    path: Endpoints.LOCATION,
    element: LazyLoad(() => import('@/pages/location'))
  },
  {
    path: Endpoints.DELIVERY_SERVICE,
    element: LazyLoad(() => import('@/pages/delivery'))
  },
  {
    path: Endpoints.PRINT_HISTORY,
    element: <AuthGuards>{LazyLoad(() => import('@/pages/print-history'))}</AuthGuards>
  },
  {
    path: Endpoints.PRINT,
    element: <AuthGuards>{LazyLoad(() => import('@/pages/print'))}</AuthGuards>
  },
  {
    path: '*',
    element: LazyLoad(() => import('@/pages/not-found'))
  }
];
