export enum EPaymentMethod {
  QR = 'QR',
  GOPAY = 'GOPAY',
  OVO = 'OVO',
  SHOPEEPAY = 'SHOPEEPAY',
  LINKAJA = 'LINKAJA',
  DANA = 'DANA',
  BANK_TRANSFER_BCA = 'BANK_TRANSFER_BCA',
  BANK_TRANSFER_BNI = 'BANK_TRANSFER_BNI',
  BANK_TRANSFER_BRI = 'BANK_TRANSFER_BRI',
  BANK_TRANSFER_MANDIRI = 'BANK_TRANSFER_MANDIRI'
}
