import { AuthServices } from './auth.services';
import { LocationServices } from './location.services';
import { PrintServices } from './print.services';
import { PriceServices } from './price.services';
import { ConvertServices } from './convert.services';
import { TransactionServices } from './transaction.services';
import { InformationServices } from './information.services';

export const Services = {
  ...AuthServices,
  ...InformationServices,
  ...LocationServices,
  ...PrintServices,
  ...PriceServices,
  ...ConvertServices,
  ...TransactionServices
};
