import { EPaymentMethod } from '@/domains/modules';
import {
  IMGBcaLogo,
  IMGBniLogo,
  IMGDanaLogo,
  IMGGopayLogo,
  IMGLinkajaLogo,
  IMGMandiriLogo,
  IMGBriLogo,
  IMGOvoLogo,
  IMGQrisLogo,
  IMGShopeePayLogo
} from '@/assets/images';

export const kPaymentMethodImage = {
  [EPaymentMethod.QR]: IMGQrisLogo,
  [EPaymentMethod.GOPAY]: IMGGopayLogo,
  [EPaymentMethod.OVO]: IMGOvoLogo,
  [EPaymentMethod.SHOPEEPAY]: IMGShopeePayLogo,
  [EPaymentMethod.LINKAJA]: IMGLinkajaLogo,
  [EPaymentMethod.DANA]: IMGDanaLogo,
  [EPaymentMethod.BANK_TRANSFER_BCA]: IMGBcaLogo,
  [EPaymentMethod.BANK_TRANSFER_BNI]: IMGBniLogo,
  [EPaymentMethod.BANK_TRANSFER_BRI]: IMGBriLogo,
  [EPaymentMethod.BANK_TRANSFER_MANDIRI]: IMGMandiriLogo
};
