import { Box, Text, styled } from '@chakra-ui/react';
import React from 'react';
import { BiLock } from 'react-icons/bi';

const LockIcon = styled(BiLock);

const FeatureLock: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box position="relative" cursor="not-allowed">
      <Box
        width="100%"
        height="100%"
        background="repeating-linear-gradient(
          45deg,
          #ffffffdd,
          #ffffffdd 10px,
          #eeeeeedd 10px,
          #eeeeeedd 20px
        )"
        border="4px solid #eee"
        position="absolute"
        zIndex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap="2%"
        fontWeight={700}
      >
        <LockIcon width="1.3rem" height="1.3rem" />
        <Text>This feature is currently unavailable</Text>
      </Box>
      <Box pointerEvents="none">{children}</Box>
    </Box>
  );
};

export default FeatureLock;
