/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios';
import axios, { AxiosError } from 'axios';

type TAxiosAfterResponseProps<Response, Params, Error> = {
  /** Axios hooks when request has not been sent to server. */
  onRequest?: (
    value: InternalAxiosRequestConfig<Response>
  ) =>
    | InternalAxiosRequestConfig<Response>
    | Promise<InternalAxiosRequestConfig<Response>>;

  /** Axios hooks when request has been sent to server and response is successfully received. */
  onSuccess?: (
    value: AxiosResponse<Response, Params>
  ) => AxiosResponse<Response, Params> | Promise<AxiosResponse<Response, Params>>;
  /** Axios hooks when request has been sent to server and it sends back an error. */
  onError?: (error: AxiosError<Error>) => Error;
};

export default class Http<BaseResponse = any, BaseParams = any, BaseError = unknown> {
  private instance: AxiosInstance;

  private config: AxiosRequestConfig<BaseResponse>;

  private callback:
    | TAxiosAfterResponseProps<BaseResponse, BaseParams, BaseError>
    | undefined;

  constructor(
    config?: AxiosRequestConfig<BaseResponse>,
    callback?: TAxiosAfterResponseProps<BaseResponse, BaseParams, BaseError>
  ) {
    const conf: AxiosRequestConfig<BaseResponse> = {
      timeout: 6 * 60 * 1000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...config?.headers
      },
      ...config
    };
    this.instance = axios.create(conf);
    this.config = conf;
    this.callback = callback;
    this.instance.interceptors.request.use(callback?.onRequest);
    this.instance.interceptors.response.use(callback?.onSuccess, callback?.onError);
  }

  /**
   * Updates the configuration of the Http instance, and recreates the http instance with the new config.
   * @param instance axios instance which will translate the response and errors of the promise.
   */
  private async axiosHandler(instance: Promise<AxiosResponse<BaseResponse, BaseParams>>) {
    return await instance
      .then((response) => {
        this.callback?.onSuccess?.(response);
        return response.data;
      })
      .catch((error: AxiosError<BaseError>) => {
        this.callback?.onError?.(error);
        throw error;
      });
  }

  /**
   * Updates the configuration of the Http instance, and recreates the http instance with the new config.
   * @param config axios config
   */
  updateConfig(config: AxiosRequestConfig<BaseResponse>): void {
    const newConfig = { ...this.config, ...config };
    this.config = newConfig;
    this.instance = axios.create(newConfig);
  }

  /**
   * GET operation
   * [Read more](https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET)
   * @param url URL to the endpoint
   * @param config Configuration object
   * @returns The response from endpoint
   */
  async get(
    url: string,
    config?: AxiosRequestConfig<BaseResponse>
  ): Promise<BaseResponse> {
    return this.axiosHandler(this.instance.get(url, config));
  }

  /**
   * DELETE operation
   * [Read more](https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/DELETE)
   * @param url URL to the endpoint
   * @param config Configuration object
   * @returns The response from endpoint
   */
  async delete(
    url: string,
    config?: AxiosRequestConfig<BaseResponse>
  ): Promise<BaseResponse> {
    return this.axiosHandler(this.instance.delete(url, config));
  }

  /**
   * POST operation
   * [Read more](https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST)
   * @param url URL to the endpoint
   * @param data Request params to be sent to the endpoint
   * @param config Configuration object
   * @returns The response from endpoint
   */
  async post(
    url: string,
    data: any = {},
    config?: AxiosRequestConfig<BaseResponse>
  ): Promise<BaseResponse> {
    return this.axiosHandler(this.instance.post(url, data, config));
  }

  /**
   * PUT operation
   * [Read more](https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT)
   * @param url URL to the endpoint
   * @param data Request params to be sent to the endpoint
   * @param config Configuration object
   * @returns The response from endpoint
   */
  async put(
    url: string,
    data: any,
    config?: AxiosRequestConfig<BaseResponse>
  ): Promise<BaseResponse> {
    return this.axiosHandler(this.instance.put(url, data, config));
  }

  /**
   * PATCH operation
   * [Read more](https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH)
   * @param url URL to the endpoint
   * @param data Request params to be sent to the endpoint
   * @param config Configuration object
   * @returns The response from endpoint
   */
  async patch(
    url: string,
    data: any,
    config?: AxiosRequestConfig<BaseResponse>
  ): Promise<BaseResponse> {
    return this.axiosHandler(this.instance.patch(url, data, config));
  }
}
