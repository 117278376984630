import { EPaperCode } from '@/domains/common';

export type TGetPrintingPriceListParams = undefined;

type TPrice = {
  min_page: number;
  price: number;
};

export type TGetPrintingPriceListResponse = {
  bw_prices: TPrice[];
  color_prices: TPrice[];
};

export const kPaperPriceKey: Record<EPaperCode, keyof TGetPrintingPriceListResponse> = {
  [EPaperCode.BW]: 'bw_prices' as const,
  [EPaperCode.COLOR]: 'color_prices' as const
};
