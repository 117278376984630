export enum EPaperCode {
  BW = 'BW',
  COLOR = 'Color'
}
export const kPaperCode = {
  [EPaperCode.BW]: 'Hitam Putih',
  [EPaperCode.COLOR]: 'Warna'
};

export enum EMimeType {
  PDF = 'application/pdf',
  PNG = 'image/png',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
}

export const kMimeTypeExtension = {
  [EMimeType.XLS]: 'xls',
  [EMimeType.XLSX]: 'xlsx',
  [EMimeType.PPT]: 'ppt',
  [EMimeType.PPTX]: 'pptx',
  [EMimeType.DOC]: 'doc',
  [EMimeType.DOCX]: 'docx'
};
