import {
  ICOBriefcase,
  ICOComment,
  ICOHistory,
  ICOHome,
  ICOPrinter,
  ICOSmile,
  ICOTruck
} from '@/assets/icon';
import { Endpoints } from '@/configs/common/endpoints';
import { useAuth } from '@/context/authUserProvider';
import { useWindowSize } from '@/hooks/custom';
import { Button } from '@chakra-ui/button';
import { useDisclosure, useOutsideClick } from '@chakra-ui/hooks';
import { HamburgerIcon, SearchIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/input';
import { Box, HStack, Heading, Icon, VStack, styled } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useRef, useState } from 'react';
import { BiMapAlt } from 'react-icons/bi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LoginWrapper } from '..';
import queryString from 'query-string';

const ChLink = styled(Link);

const Logout = () => {
  const { logOut } = useAuth();

  const handleLogOut = () => {
    logOut().then(() => window.location.reload());
  };

  return (
    <Button width="100%" colorScheme="red" onClick={handleLogOut}>
      Logout
    </Button>
  );
};

const NavigationMenu = () => {
  const { authUser } = useAuth();

  const Navigation = [
    {
      active: true,
      icon: ICOHome,
      link: Endpoints.HOME,
      name: 'Beranda'
    },
    {
      active: authUser,
      icon: ICOPrinter,
      link: Endpoints.PRINT,
      name: 'Cetak Dokumen'
    },
    {
      active: authUser,
      icon: ICOHistory,
      link: Endpoints.PRINT_HISTORY,
      name: 'Riwayat Transaksi'
    },
    {
      active: true,
      icon: ICOTruck,
      link: Endpoints.DELIVERY_SERVICE,
      name: 'Layanan Antar'
    },
    {
      active: true,
      icon: BiMapAlt,
      link: Endpoints.LOCATION,
      name: 'Lokasi Printbox'
    },
    {
      active: true,
      icon: ICOComment,
      link: Endpoints.FAQ,
      name: 'FAQ'
    },
    {
      active: true,
      icon: ICOSmile,
      link: Endpoints.ABOUT,
      name: 'tentang kami'
    },
    {
      active: true,
      icon: ICOBriefcase,
      link: 'https://api.whatsapp.com/send?phone=6289690902020&text=Internship%20Opportunity',
      name: 'Internship'
    }
  ];

  return (
    <VStack alignItems="flex-start" gridGap="10px">
      {Navigation.map((data, index) => {
        return (
          data.active && (
            <ChLink to={data.link} key={index} width="100%">
              <Button
                color="darkBlue"
                leftIcon={<Icon as={data.icon} />}
                variant="ghost"
                textTransform="capitalize"
                fontWeight="bold"
                width="100%"
                alignItems="center"
                justifyContent="flex-start"
              >
                {data.name}
              </Button>
            </ChLink>
          )
        );
      })}
    </VStack>
  );
};

const LogoSection: React.FC<{ onToggle(): void }> = ({ onToggle }) => (
  <HStack width="100%" cursor="pointer">
    <Button onClick={onToggle} variant="ghost">
      <HamburgerIcon />
    </Button>
    <Link to="/">
      <Heading m="0" as="h4" size="md">
        Printbox
      </Heading>
    </Link>
  </HStack>
);

const Navbar = () => {
  const { isTabletDisplay } = useWindowSize();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const ref = useRef(null);
  const navigate = useNavigate();
  const { search: query } = useLocation();
  const parsedQuery = queryString.parse(query);
  const [search, setSearch] = useState(String(parsedQuery.search_loc ?? ''));
  const [searchShow, setSearchShow] = useState(false);
  const { authUser } = useAuth();

  useOutsideClick({
    ref: ref,
    handler: onClose
  });

  const handleSearch = () => {
    if (search)
      navigate({
        pathname: Endpoints.LOCATION,
        search: queryString.stringify({ search_loc: search })
      });
  };

  return (
    <Box
      minH="inherit"
      height="100%"
      width="inherit"
      position="sticky"
      top={0}
      zIndex={999}
      alignItems="flex-start"
      color="telegram.900"
    >
      <HStack
        p={isTabletDisplay ? '10px' : '1rem 2rem'}
        width="inherit"
        alignItems="center"
        justifyContent="space-between"
        backgroundColor="#fff"
        height="100%"
        minH="inherit"
      >
        {!searchShow && <LogoSection onToggle={onToggle} />}
        <Box flex={isTabletDisplay ? '1' : ''}>
          {isTabletDisplay ? (
            <>
              {!searchShow ? (
                <Button size="sm" variant="ghost" onClick={() => setSearchShow(true)}>
                  <SearchIcon />
                </Button>
              ) : (
                <motion.div
                  initial={{ scaleX: 0 }}
                  animate={{ scaleX: '100%' }}
                  exit={{ scaleX: 0 }}
                  transition={{ type: 'tween', duration: 0.2, ease: 'easeIn' }}
                  style={{ transformOrigin: 'right' }}
                >
                  <InputGroup size="md">
                    <InputLeftElement pointerEvents="none">
                      <SearchIcon />
                    </InputLeftElement>
                    <Input
                      borderRadius="5px"
                      borderColor="telegram.800"
                      borderWidth="thin"
                      type="text"
                      placeholder="Cari Printbox Terdekat..."
                      value={search}
                      onKeyUp={(e) => e.key === 'Enter' && handleSearch()}
                      onChange={(e) => setSearch(e.target.value)}
                      onBlur={() => setSearchShow(false)}
                    />
                  </InputGroup>
                </motion.div>
              )}
            </>
          ) : (
            <InputGroup size="md">
              <InputLeftElement pointerEvents="none">
                <SearchIcon />
              </InputLeftElement>
              <Input
                width="280px"
                fontSize="14px"
                borderRadius="5px"
                borderColor="telegram.800"
                borderWidth="thin"
                type="text"
                placeholder="Cari Printbox Terdekat..."
                value={search}
                onKeyUp={(e) => e.key === 'Enter' && handleSearch()}
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>
          )}
        </Box>
      </HStack>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: 'tween' }}
            style={{
              backgroundColor: '#22222212',
              position: 'fixed',
              top: '0',
              left: '0',
              zIndex: 999,
              width: '100%',
              height: '100%'
            }}
          >
            {isOpen && (
              <motion.div
                initial={{ x: '-100%' }}
                animate={{ x: 0 }}
                exit={{ x: '-100%' }}
                transition={{ type: 'tween' }}
                style={{ display: 'flex', height: '100%' }}
              >
                <VStack
                  width="fit-content"
                  p="1rem"
                  backgroundColor="#fff"
                  mt="0"
                  gridGap="4rem"
                  ref={ref}
                >
                  <VStack flex="1" gridGap="1rem">
                    <LogoSection onToggle={onToggle} />
                    <NavigationMenu />
                  </VStack>
                  {!authUser ? (
                    <LoginWrapper>
                      <Button width="100%" backgroundColor="yellow" color="white">
                        Login
                      </Button>
                    </LoginWrapper>
                  ) : (
                    <Logout />
                  )}
                </VStack>
              </motion.div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default Navbar;
