import { ApiEndpoints } from '@/domains/endpoints';
import {
  TGetActiveCouponResponse,
  TGetUserTransactionCountResponse
} from '@/domains/modules';
import { MainAPI } from './services.config';
import { TCommonResponse } from '@/domains/common';

export const TransactionServices = {
  getUserTransactionCount: (): Promise<
    TCommonResponse<TGetUserTransactionCountResponse>
  > => MainAPI().get(ApiEndpoints.USER_TRANSACTION_COUNT),
  getActiveCoupon: (): Promise<TCommonResponse<TGetActiveCouponResponse>> =>
    MainAPI().get(ApiEndpoints.ACTIVE_COUPON)
};
