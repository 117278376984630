import { ApiEndpoints } from '@/domains/endpoints';
import {
  TGenerateTaskParams,
  TGenerateTaskResponse,
  TGetPrintHistoryResponse,
  TGetPrintableDocumentsResponse
} from '@/domains/modules';
import { MainAPI } from './services.config';
import { TCommonResponse } from '@/domains/common';

export const PrintServices = {
  getPrintHistory: (): Promise<TGetPrintHistoryResponse> =>
    MainAPI().get(ApiEndpoints.PRINTING_USER),
  generateTask: (params: TGenerateTaskParams): Promise<TGenerateTaskResponse> =>
    MainAPI().post(ApiEndpoints.PRINTING, params),
  getPrintableDocuments: (): Promise<TCommonResponse<TGetPrintableDocumentsResponse>> =>
    MainAPI().get(ApiEndpoints.PRINTABLE_DOCUMENT)
};
