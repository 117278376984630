import { ApiEndpoints } from '@/domains/endpoints';
import { MainAPI } from './services.config';
import { TCommonResponse } from '@/domains/common';
import { TGetFAQListResponse, TGetTickerResponse } from '@/domains/modules';

export const InformationServices = {
  getTicker: (): Promise<TCommonResponse<TGetTickerResponse>> =>
    MainAPI().get(ApiEndpoints.TICKER),
  getFAQList: (): Promise<TGetFAQListResponse> => MainAPI().get(ApiEndpoints.FAQ_PRINT)
};
