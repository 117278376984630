export enum ApiEndpoints {
  CONVERT_TO_PDF_URL = 'https://api.cloudmersive.com/convert/:type/to/pdf',
  TICKER = '/utils/ticker/',
  AUTH = '/gauth/get-token/',
  LOCATION_PRINT = '/locateme_revamp/',
  FAQ_PRINT = '/faq/',
  ACTIVE_PAYMENT = '/active_payment_option',
  PAYMENT = '/payment/development/generate/',
  PAYMENT_CONFIRMATION = '/payment/user_confirmation',
  PROMO = '/printing/check_promo',
  PRINTING = '/printing/',
  PRINTING_PRICE = '/printing/price',
  PRINTING_PRICE_LIST = '/price_list',
  PRINTING_PLATFORM_FEE = '/printing/platform_fee',
  PRINTING_USER = '/printing/user/',
  PRINTING_SPECIFIC = '/printing/specific/',
  BANK_TRANSFER = '/webhook/payment/bank_transfer/',
  USER_TRANSACTION_COUNT = '/transaction_count',
  ACTIVE_COUPON = '/active_coupon',
  PRINTABLE_DOCUMENT = '/printable_document'
}
