import { TCredentialCookies, credentialCookies } from '@/configs/common';
import { firebaseEnv } from '@/configs/env/firebase.env';
import { Services } from '@/services';
import { initializeApp } from 'firebase/app';
import type { NextOrObserver, User, UserCredential } from 'firebase/auth';
import {
  GoogleAuthProvider,
  getAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  signInWithPopup,
  signOut
} from 'firebase/auth';
import React, { createContext, useCallback, useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useAlert } from '../alertProvider';

type TUser = {
  displayName: string | null;
  email: string | null;
  photoURL: string | null;
  backendAccessToken: string | null;
};

export const AuthUserContext = createContext<{
  logOut: () => Promise<void>;
  signInGoogle: () => Promise<UserCredential | undefined>;
  getAccessToken: (authUser: TUser) => void;
  authUser: (TUser & { backendAccessToken: string }) | undefined;
}>({
  logOut: async () => {},
  signInGoogle: async () => undefined,
  getAccessToken: () => '',
  authUser: undefined
});

const formatAuthUser = ({
  displayName,
  email,
  photoURL
}: UserCredential['user']): TUser => ({
  photoURL,
  displayName,
  email,
  backendAccessToken: null
});

const DEFAULT_HOUR_TO_EXPIRE = 1;
const formattedHour = (hourToExpire?: number) =>
  (hourToExpire || DEFAULT_HOUR_TO_EXPIRE) * 60 * 60 * 1000;

const app = initializeApp(firebaseEnv);
const auth = getAuth(app);

const AuthUserProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies([credentialCookies.authUser]);
  const authUser = cookies[credentialCookies.authUser];
  const { handleError } = useAlert();

  const clear = () => {
    removeCookie(credentialCookies.authUser);
  };

  const signInGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };

  const logOut = () => auth && signOut(auth).then(clear);

  const createCookieInMinute = useCallback(
    (
      cookieName: TCredentialCookies,
      cookieValue: TUser & { backendAccessToken: string },
      hourToExpire: number
    ) => {
      const date = new Date();
      date.setTime(date.getTime() + formattedHour(hourToExpire));
      setCookie(cookieName, cookieValue, {
        path: '/',
        expires: date
      });
    },
    [setCookie]
  );

  const getAccessToken = useCallback(
    async (authUser: TUser) => {
      const accessToken = (await auth.currentUser?.getIdToken()) ?? '';
      Services.auth({ id_token: accessToken })
        .then((data) => {
          createCookieInMinute(
            credentialCookies.authUser,
            { ...authUser, backendAccessToken: data.access_token },
            24
          );
        })
        .catch(handleError);
    },
    [createCookieInMinute, handleError]
  );

  useEffect(() => {
    const handleAuthStateChanged: NextOrObserver<User> = async (authState) => {
      if (!authState) {
        removeCookie(credentialCookies.authUser);
        return;
      }

      const authUser = formatAuthUser(authState);
      getAccessToken(authUser);
    };

    const firebaseTokenRefresh = setInterval(() => {
      if (auth.currentUser) {
        auth.currentUser.getIdToken(true);
      } else {
        removeCookie(credentialCookies.authUser);
      }
    }, formattedHour());

    const authSubscribe = onAuthStateChanged(auth, handleAuthStateChanged);
    const tokenRefreshSubscribe = onIdTokenChanged(auth, handleAuthStateChanged);
    return () => {
      clearInterval(firebaseTokenRefresh);
      authSubscribe();
      tokenRefreshSubscribe();
    };
  }, [getAccessToken, removeCookie]);

  return (
    <AuthUserContext.Provider
      value={{
        logOut,
        signInGoogle,
        getAccessToken,
        authUser
      }}
    >
      {children}
    </AuthUserContext.Provider>
  );
};

export const useAuth = () => useContext(AuthUserContext);

export default AuthUserProvider;
