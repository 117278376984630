import { firebaseEnv } from './firebase.env';
import { mainEnv } from './main.env';
import { midtransEnv } from './midtrans.env';
import { sentryEnv } from './sentry.env';

export const Env = {
  ...mainEnv,
  ...firebaseEnv,
  ...sentryEnv,
  ...midtransEnv
};
