import Http from '@/bootstrap/axios.bootstrap';
import { credentialCookies } from '@/configs/common';
import { Env } from '@/configs/env';
import Cookies from 'universal-cookie';
import * as Yup from 'yup';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MainAPI = (schema?: Yup.ObjectSchema<any>) => {
  const cookies = new Cookies();

  return new Http(
    {
      baseURL: Env.apiUrl,
      headers: {
        Authorization: `Bearer ${cookies.get(credentialCookies.authUser)?.backendAccessToken}`
      }
    },
    {
      onRequest: async (req) => {
        await schema?.validate(req.data);
        return req;
      }
    }
  );
};
