import { EPaperCode } from '@/domains/common';

export type TGetPrintingPriceParams = {
  bw_pages?: number;
  color_pages?: number;
};

export type TGetPrintingPriceResponse = {
  [EPaperCode.BW]: number;
  [EPaperCode.COLOR]: number;
};

export const kPaperPageKey = {
  [EPaperCode.BW]: 'bw_pages',
  [EPaperCode.COLOR]: 'color_pages'
} as const;
