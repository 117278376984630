import { Alert, AlertIcon } from '@chakra-ui/alert';
import { useDisclosure } from '@chakra-ui/hooks';
import * as Sentry from '@sentry/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { createContext, useContext, useEffect, useState } from 'react';

export const AlertContext = createContext<{
  alertShow: () => void;
  alertHide: () => void;
  setAlertStatus: React.Dispatch<
    React.SetStateAction<'error' | 'info' | 'warning' | 'success' | 'loading'>
  >;
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
  handleError: (error: unknown, extra?: unknown) => void;
}>({
  alertShow: () => {},
  alertHide: () => {},
  setAlertStatus: () => {},
  setAlertMessage: () => {},
  handleError: () => {}
});

const AlertProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<
    'error' | 'info' | 'warning' | 'success' | 'loading'
  >('error');

  useEffect(() => {
    const time = setTimeout(() => {
      onClose();
      setMessage('');
    }, 5000);
    return () => {
      clearTimeout(time);
    };
  }, [isOpen, onClose]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleError = (error: any, extra?: any) => {
    setStatus('error');

    if (typeof error === 'string') {
      setMessage(error);
      onOpen();
      return;
    }

    Sentry.captureException(error, { extra });

    if (error.response) {
      if (error.response.data.detail) {
        setMessage(error.response.data.detail);
      } else if (error.response.status >= 500 && error.response.status < 600) {
        setMessage('Terjadi kesalahan pada server. Silakan kontak administrator!');
      } else if (error.response.status >= 400 && error.response.status < 500) {
        setMessage('Data tidak valid! Hubungi administrator segera!');
      }
    } else if (error.message === 'Network Error') {
      setMessage(
        'Network Error. Pastikan koneksi internet anda lancar atau hubungi administrator!'
      );
    } else {
      setMessage('Error tidak terdeteksi, kontak administrator segera!');
    }
    onOpen();
    return;
  };

  return (
    <AlertContext.Provider
      value={{
        alertShow: onOpen,
        alertHide: onClose,
        setAlertStatus: setStatus,
        setAlertMessage: setMessage,
        handleError
      }}
    >
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ y: '110%' }}
            animate={{ y: 0 }}
            exit={{ y: '110%' }}
            style={{
              position: 'fixed',
              bottom: '1rem',
              left: '50%',
              translateX: '-50%',
              width: 'fit-content',
              paddingInline: '1rem',
              zIndex: '999'
            }}
          >
            <Alert borderRadius="md" status={status}>
              <AlertIcon />
              {message}
            </Alert>
          </motion.div>
        )}
      </AnimatePresence>
      {children}
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);

export default AlertProvider;
