import { themeConfig } from '@/configs/ui';
import { useEffect, useState } from 'react';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
    isLaptopDisplay: false,
    isTabletDisplay: false,
    isMobileDisplay: false
  });

  useEffect(() => {
    const onResize = () => {
      const getSize = {
        width: window.outerWidth,
        height: window.outerHeight,
        isLaptopDisplay:
          window.outerWidth <= themeConfig.breakpoints.xl ||
          window.innerWidth <= themeConfig.breakpoints.xl, // xl
        isTabletDisplay:
          window.outerWidth <= themeConfig.breakpoints.lg ||
          window.innerWidth <= themeConfig.breakpoints.lg, // lg
        isMobileDisplay:
          window.outerWidth <= themeConfig.breakpoints.sm ||
          window.innerWidth <= themeConfig.breakpoints.sm // sm
      };
      setWindowSize(getSize);
    };
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return windowSize;
};

export default useWindowSize;
