export const themeConfig = {
  colors: {
    yellow: '#FFB600',
    paleBlue: '#76DFF5',
    paleBlue2: '#BEE9EF',
    // blue: '#007BB8',
    darkBlue: '#2E4B77',
    darkBlue2: '#080E28'
  },
  breakpoints: {
    base: 0,
    sm: 480,
    md: 768,
    lg: 960,
    xl: 1200,
    '2xl': 1536
  },
  fonts: {
    heading: 'poppins',
    body: 'poppins'
  }
};
