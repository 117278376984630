import { ApiEndpoints } from '@/domains/endpoints';
import { MainAPI } from './services.config';
import {
  TGetActivePaymentOptionsResponse,
  TGetPlatformPriceParams,
  TGetPlatformPriceResponse,
  TGetPrintingPriceListParams,
  TGetPrintingPriceListResponse,
  TGetPrintingPromoParams,
  TGetPrintingPromoResponse,
  TSendBankTransferWebhookParams,
  TSendBankTransferWebhookResponse,
  TSendPaymentParams,
  TSendPaymentResponse
} from '@/domains/modules';
import { TCommonResponse } from '@/domains/common';
import {
  TSendQRISPaymentConfirmationParams,
  TSendQRISPaymentConfirmationResponse
} from '@/domains/modules/price/sendQRISPaymentConfirmation.types';
import {
  TGetPrintingPriceParams,
  TGetPrintingPriceResponse
} from '@/domains/modules/price/getPrintingPrice.types';

export const PriceServices = {
  getPrintingPrice: (
    params?: TGetPrintingPriceParams
  ): Promise<TGetPrintingPriceResponse> => {
    return MainAPI().get(ApiEndpoints.PRINTING_PRICE, { params });
  },

  getPrintingPriceList: (): Promise<TCommonResponse<TGetPrintingPriceListResponse>> => {
    return MainAPI().get(ApiEndpoints.PRINTING_PRICE_LIST);
  },

  getPlatformPrice: (
    params?: TGetPlatformPriceParams
  ): Promise<TCommonResponse<TGetPlatformPriceResponse>> => {
    return MainAPI().get(ApiEndpoints.PRINTING_PLATFORM_FEE, { params });
  },

  getPrintingPromo: (
    params: TGetPrintingPromoParams
  ): Promise<TGetPrintingPromoResponse> => {
    return MainAPI().get(ApiEndpoints.PROMO, { params });
  },

  sendPayment: (params: TSendPaymentParams): Promise<TSendPaymentResponse> => {
    return MainAPI().post(ApiEndpoints.PAYMENT, params);
  },

  sendBankTransferWebhook: (
    params: TSendBankTransferWebhookParams
  ): Promise<TCommonResponse<TSendBankTransferWebhookResponse>> => {
    return MainAPI().post(ApiEndpoints.BANK_TRANSFER, params);
  },

  sendQRISPaymentConfirmation: (
    params: TSendQRISPaymentConfirmationParams
  ): Promise<TCommonResponse<TSendQRISPaymentConfirmationResponse>> => {
    return MainAPI().post(ApiEndpoints.PAYMENT_CONFIRMATION, params);
  },

  getActivePaymentOptions: (): Promise<
    TCommonResponse<TGetActivePaymentOptionsResponse>
  > => {
    return MainAPI().post(ApiEndpoints.ACTIVE_PAYMENT);
  }
};
