import { credentialCookies } from '@/configs/common';
import { Endpoints } from '@/configs/common/endpoints';
import { useAlert } from '@/context/alertProvider';
import { useAuth } from '@/context/authUserProvider';
import { Box } from '@chakra-ui/layout';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const LoginWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [cookie] = useCookies([credentialCookies.authUser]);
  const { signInGoogle } = useAuth();
  const navigate = useNavigate();
  const { handleError } = useAlert();

  const handleClick = () => {
    if (cookie[credentialCookies.authUser]) return navigate(Endpoints.PRINT);

    signInGoogle()
      .then(() => {
        navigate(Endpoints.PRINT);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((e: any) => {
        if (
          e.code === 'auth/cancelled-popup-request' ||
          e.code === 'auth/popup-closed-by-user'
        )
          return;
        else handleError(e);
      });
  };

  return (
    <Box width="100%" onClick={handleClick}>
      {children}
    </Box>
  );
};

export default LoginWrapper;
