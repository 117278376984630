import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { CookiesProvider } from 'react-cookie';
import { themeConfig } from './configs/ui';
import { AuthUserProvider } from './context/authUserProvider';
import Router from './routes/routes.config';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone'; // dependent on utc plugin
import { sentryInitialize } from './configs/lib';
import { Env } from './configs/env';
import { pdfjs } from 'react-pdf';

dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

if (Env.environment === 'production' || Env.environment === 'staging') {
  sentryInitialize();
}

const theme = extendTheme(themeConfig);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1
    },
    mutations: {
      retry: 1
    }
  }
});

const App = () => {
  return (
    <CookiesProvider>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme}>
            <AuthUserProvider>
              <ReactQueryDevtools />
              <Router />
            </AuthUserProvider>
          </ChakraProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </CookiesProvider>
  );
};

export default App;
