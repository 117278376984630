import { useAlert } from '@/context/alertProvider';
import { Box, Text, VStack } from '@chakra-ui/layout';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import type { Accept } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';

const DropzoneContainer = styled.div`
  border: 1px dashed #aaa;
  border-radius: 5px;
  padding: 2rem;
  text-align: center;
  font-size: 12px;
  color: #aaa;
  cursor: pointer;

  &:hover {
    color: #222;
    border: 1px dashed #222;
  }
`;

const ReactDropzone: React.FC<
  React.PropsWithChildren<{
    isVisible?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleFile: (_data: { file: any; payload: string | ArrayBuffer | null }) => void;
    acceptedFiles?: Accept;
    maxSize?: number;
  }>
> = ({ isVisible, handleFile, children, acceptedFiles, maxSize }) => {
  const { handleError } = useAlert();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        // eslint-disable-next-line no-console
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = (e) => {
          // eslint-disable-next-line no-console
          console.log(e);
          handleError(
            'Harap ulangi kembali upload file, atau hubungi bantuan Whatsapp!',
            e
          );
        };
        reader.onload = () => {
          const binaryStr = reader.result;
          const fileObj = {
            file,
            payload: binaryStr
          };
          handleFile(fileObj);
        };
        reader.readAsDataURL(file);
      });
    },
    [handleError, handleFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected: (fileRejection) => {
      handleError(fileRejection?.[0].errors?.[0].message);
    },
    accept: acceptedFiles || { 'image/*': [] },
    maxSize
  });

  return (
    <>
      {isVisible && (
        <VStack width="100%" height="100%">
          {children ? (
            <Box
              {...getRootProps()}
              onDrop={(data) => onDrop(Array.from(data.dataTransfer.files))}
              width="fit-content"
            >
              <input
                {...getInputProps()}
                onChange={(data) =>
                  onDrop(data.target.files ? Array.from(data.target.files) : [])
                }
              />
              {children}
            </Box>
          ) : (
            <DropzoneContainer
              {...getRootProps()}
              onDrop={(data) => onDrop(Array.from(data.dataTransfer.files))}
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <input
                {...getInputProps()}
                onChange={(data) =>
                  onDrop(data.target.files ? Array.from(data.target.files) : [])
                }
              />
              <Text>Klik atau seret file disini untuk mengunggah</Text>
              <Text fontWeight="bold">
                Docx, Doc, PPT, PPTX, XLS, XLSX ({'<'} 3MB), PNG, JPG ({'<'} 5MB), dan PDF
                ({'<'} 50MB)
              </Text>
            </DropzoneContainer>
          )}
        </VStack>
      )}
    </>
  );
};

export default ReactDropzone;
