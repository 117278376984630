import { lazy, Suspense } from 'react';

import { ErrorBoundary } from '@/components';

export function LazyComponent<Props>(
  path: () => Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: React.ComponentType<any>;
  }>,
  loader?: JSX.Element | string
) {
  const LazyComponent = lazy(path);

  return function HOCLazyComponent({
    hocLoader,
    ...props
  }: React.PropsWithChildren<Props> & { hocLoader?: JSX.Element | string }) {
    return (
      <ErrorBoundary>
        {/**
         * Since we also need a custom loader from LazyComponent, there will be loader for
         * lazy loading the component, and HOCLoader for lazy loading route.
         */}
        <Suspense fallback={loader || hocLoader || <></>}>
          <LazyComponent {...props} />
        </Suspense>
      </ErrorBoundary>
    );
  };
}

export function LazyLoad(
  path: () => Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: React.ComponentType<any>;
  }>,
  loader?: JSX.Element | string
) {
  const LazyElement = LazyComponent(path);

  return <LazyElement hocLoader={loader} />;
}
