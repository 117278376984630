import { ApiEndpoints } from '@/domains/endpoints';
import { MainAPI } from './services.config';

type TAuthParams = { id_token: string };
type TAuthResponse = { access_token: string };

export const AuthServices = {
  auth: (params: TAuthParams): Promise<TAuthResponse> =>
    MainAPI().post(ApiEndpoints.AUTH, params)
};
