import { Box, Divider, Heading, styled } from '@chakra-ui/react';
import { Component, ErrorInfo } from 'react';
import * as Sentry from '@sentry/react';
import type { ErrorBoundaryProps, ErrorBoundaryState } from './ErrorBoundary.types';

const Details = styled('details');

const initState = { error: null, errorInfo: null };
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = initState;

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (error.toString().toLowerCase().includes('dynamically imported')) {
      return window.location.reload();
    }
    this.setState({ error, errorInfo });

    Sentry.withScope((scope) => {
      scope.setExtras({ errorInfo: errorInfo.componentStack });
      Sentry.captureException(error);
    });
  }

  public render() {
    if (this.state.error && this.state.errorInfo) {
      if (this.props.fallbackElement)
        return this.props.fallbackElement({
          error: this.state.error,
          errorInfo: this.state.errorInfo,
          resetError: () => {
            this.setState(initState);
            this.props.onRefresh?.();
          }
        });

      // Error path
      return (
        <Box background="transparent" padding="1rem">
          <Box backgroundColor="red.200" borderColor="red" rounded="md">
            <Box padding="1rem">
              <Heading fontSize="large">
                {this.state.error && this.state.error.toString()}
              </Heading>
            </Box>
            <Divider borderColor="red" orientation="horizontal" />
            <Box padding="1rem" whiteSpace="pre-line">
              <Details
                color="red.700"
                cursor="pointer"
                maxHeight="240px"
                overflowY="auto"
              >
                {this.state.errorInfo.componentStack}
              </Details>
            </Box>
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
