import { credentialCookies } from '@/configs/common';
import { Endpoints } from '@/configs/common/endpoints';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const AuthGuards: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [cookies] = useCookies([credentialCookies.authUser]);
  const authUser = cookies[credentialCookies.authUser];
  const navigate = useNavigate();

  useEffect(() => {
    if (!authUser) navigate(Endpoints.HOME);
  }, [authUser, navigate]);

  return children;
};

export default AuthGuards;
